import React, { useEffect, useState, type FC } from 'react';
import styles from './datepicker.module.scss';

export type DatepickerProps = {
	dateTime?: string;
};

export const Datepicker: FC<DatepickerProps> = ({ dateTime }) => {
	const [selectedDateTime, setSelectedDateTime] = useState(dateTime ?? null);
	// add useEffect to prevent hydration issue
	useEffect(() => {
		if (!selectedDateTime) {
			setSelectedDateTime(new Date().toISOString());
		}
	}, [selectedDateTime]);

	const handleDateTimeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setSelectedDateTime(event.target.value);
	};

	const handleButtonClick = () => {
		if (!selectedDateTime) {
			return;
		}
		window.location.assign(`?preview=true&dateTime=${encodeURIComponent(selectedDateTime)}`);
	};

	return (
		<div className={styles.datepickerContainer} data-testid="date-picker-component">
			<label className={styles.datepickerLabel} htmlFor="previewDateTime">
				Select datetime to preview scheduled content
			</label>
			{selectedDateTime && (
				<p className={styles.datepickerSelectedDate}>DTime Selected: {new Date(selectedDateTime).toLocaleString()}</p>
			)}
			<div className={styles.datepicker}>
				<input
					type="datetime-local"
					id="previewDateTime"
					name="previewDateTime"
					min={(selectedDateTime || '').slice(0, -8)}
					value={selectedDateTime || ''}
					onChange={handleDateTimeChange}
				/>
				<button onClick={handleButtonClick} data-testid="date-picker-button">
					GO
				</button>
			</div>
		</div>
	);
};
