import { Container, useTheme } from '@chakra-ui/react';
import type { Tokens } from '@ldfeplatform/drx-component-library.themes.theme';
import { ViewResolverContainer, type ViewResolverContainerProps } from 'component/ViewResolverContainer';
import type { FunctionComponent } from 'react';
import { useEffect } from 'react';
import { PCH } from '../services/bff/heliosUtils';

export type HeliosTemplateProps = {
	content: ViewResolverContainerProps;
	__typename?: 'helios';
};

export const HeliosTemplate: FunctionComponent<HeliosTemplateProps> = ({ content }) => {
	const theme = useTheme<Tokens>();
	const { brandVariant } = content;
	// prevent overwriting font body by helios components
	useEffect(() => {
		const font = theme.fonts.body;
		const rootElement = document.querySelector(':root') as HTMLElement;
		// Set the value of variable --chakra-fonts-body to desired font
		rootElement.style.setProperty('--chakra-fonts-body', font);
	}, [theme.fonts.body]);

	const containerWidth = brandVariant === PCH ? theme.breakpoints['2xl'] : '100%';

	return (
		<Container maxW={containerWidth} p={0}>
			<ViewResolverContainer {...content}>
				{/* TODO: import medical locator contents as children  */}
				<h1>medical locator main contents</h1>
			</ViewResolverContainer>
		</Container>
	);
};
